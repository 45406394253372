/* 我的购物车 */
<template>
  <div id="main_MyShopCar" style="width:1200px; margin:0 auto">
    <el-container style="height:99%;">
      <el-header height="auto">
        <div class="headline">
          <el-row>
            <el-col :span="22" class="nav_bar" style="padding:0">
              <div class="nav">
                <span
                  @click="choseNav(item)"
                  v-for="item in navArr"
                  :key="item.id"
                  :class="[navtype == item.id ? 'typeActive' : '']"
                >
                  {{ item.name }}
                </span>
              </div>
            </el-col>
            <el-col :span="1" :offset="1" align="right" style="line-height: 50px;">
              <!-- <span style="margin-right:20px" class="empha" @click="$router.go(-1)">返回</span> -->
            </el-col>
          </el-row>
        </div>
        <el-row class="head_info_bar">
          <el-col :span="24" class="head_info">
            <div style="width:48px;"></div>
            <div style="width:100px;"></div>
            <div style="width:265px;">商品信息</div>
            <div style="width:198px;">商品规格</div>
            <div style="width:120px;text-align:center">单位</div>
            <div style="width:120px;">销售单价</div>
            <div style="width:135px;">订购数量</div>
            <div style="width:135px;">金额小计</div>
            <div style="width:80px;text-align:center">操作</div>
          </el-col>
        </el-row>
      </el-header>
      <el-main>
        <div class="main_box" v-for="(item, index) in tableList" :key="index">
          <div class="main_head">
            <div>
              <el-checkbox
                v-model="item.allChecked"
                @change="
                  val => {
                    allChecked_btn(val, item);
                  }
                "
              ></el-checkbox>
              <h4 style="margin-left:15px">{{ item.seller_company_name || "--" }}</h4>
              <p style="margin: 0 15px">({{ item.seller_company_code || "--" }})</p>
              <i class="el-icon-goods empha" @click="gotoHomePage(item)"></i>
            </div>
          </div>
          <div
            class="main_info"
            v-for="(it, ind) in item.sku"
            :key="ind"
            :style="`background-color:${(ind + 1) % 2 == 0 ? '#f2f2f2' : '#fff'}`"
          >
            <div style="width:48px;text-align: center;">
              <el-checkbox
                v-model="it.is_selected"
                @change="
                  val => {
                    checked_btn(val, item);
                  }
                "
              ></el-checkbox>
              <span style="margin-left:5px">{{ ind + 1 }}</span>
            </div>
            <div style="width:80px;margin-right: 20px;">
              <el-image
                style="width: 90%; height: 90%"
                :src="it.com_sku.cover_img_path"
                :preview-src-list="[it.com_sku.cover_img_path]"
                fit="contain"
              ></el-image>
            </div>
            <div style="width:253px;">
              <div
                style="margin: 10px 0;width:253px;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.name"
              >
                {{ it.com_sku && it.com_sku.name }}
              </div>
              <div
                style="margin: 10px 0;width:253px;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.serial_number"
              >
                {{ it.com_sku && it.com_sku.serial_number }}
              </div>
              <div
                style="margin: 10px 0;width:253px;"
                class="ellipsisText"
                :title="it.com_sku_small_number"
              >
                {{ it.com_sku_small_number }}
              </div>
              <div
                style="margin: 10px 0;width:253px;"
                class="ellipsisText"
                :title="it.com_sku && it.com_sku.info"
              >
                {{ it.com_sku && it.com_sku.info }}
              </div>
            </div>
            <div style="width:180px;">
              <div v-if="it.spec_json">
                <div v-for="(itt, indd) in it.spec_json" :key="indd" style="margin: 10px 0">
                  <p
                    class="ellipsisText"
                    :title="itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name"
                    v-if="indd < 2"
                  >
                    {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{ itt.spec_unit_name }}
                  </p>
                </div>
                <el-popover
                  v-if="it.spec_json.length > 2"
                  placement="right"
                  width="180"
                  trigger="hover"
                >
                  <div style="padding: 10px">
                    <p
                      v-for="(itt, indd) in it.spec_json"
                      :key="indd"
                      :title="itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name"
                    >
                      {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{
                        itt.spec_unit_name
                      }}
                    </p>
                  </div>
                  <span slot="reference">...</span>
                </el-popover>
              </div>
              <div v-else>
                --
              </div>
            </div>
            <div style="width:115px;text-align:center">
              <div class="ellipsisText" :title="it.com_sku_unit_name || '--'">
                {{ it.com_sku_unit_name || "--" }}
              </div>
            </div>
            <div style="width:114px;">
              <div class="ellipsisText" :title="it.com_sku && it.com_sku.price">
                ￥{{ it.com_sku && it.com_sku.price }}
              </div>
            </div>
            <div style="width:129px;">
              <div v-if="it.is_input">
                <el-input-number
                  style="width:122px;"
                  v-model="it.buy_count"
                  controls-position="right"
                  :min="0"
                  :precision="3"
                  :step="0.001"
                ></el-input-number>
              </div>
              <div class="ellipsisText" :title="it.buy_count" v-else>
                {{ it.buy_count || "--" }}
              </div>
            </div>
            <div style="width:129px;">
              <div class="ellipsisText" style="fontSize:16px;color:#f74848;fontWeight:600;">
                ￥{{ ((it.com_sku && it.com_sku.price) * Number(it.buy_count)).toFixed(2) }}
              </div>
            </div>
            <div style="width:75px;text-align:center">
              <div v-if="it.is_input">
                <el-link :underline="false" class="links" @click="confirm(it)">确定</el-link>
                <el-link :underline="false" class="links" @click="cancel(it)">
                  取消
                </el-link>
              </div>
              <div v-else>
                <el-link :underline="false" @click="editBtn(it)" class="links">编辑</el-link>
                <el-link :underline="false" @click="deltBtn(it)" class="links">删除</el-link>
              </div>
              <div style="margin-top:10px">
                <el-link @click="setAgain(it)" :underline="false" type="primary">
                  重选规格
                </el-link>
              </div>
            </div>
          </div>
          <div class="price_bar">
            <p>
              商品总额:
              <span style="fontSize:18px;fontWeight:600;margin-left:10px" :title="item.totalPrice">
                ￥{{ item.totalPrice.toFixed(2) || "--" }}
              </span>
            </p>
            <p>
              折扣:
              <span style="fontSize:18px;fontWeight:600;margin-left:10px">-￥0.00</span>
            </p>
            <p>
              总价:
              <span style="fontSize:18px;fontWeight:600;color:#f74848;margin-left:10px">
                ￥{{ (item.totalPrice - Number(0.0)).toFixed(2) }}
              </span>
            </p>
          </div>
        </div>
      </el-main>
      <div style="margin-top:5px">
        <div class="bot">
          <el-row class="part2">
            <el-col :span="6" :offset="1">
              <span style=" display: inline-block;margin-right:15px">已选:</span>
              <span style="fontSize:16px;color:#f74848;fontWeight:600;">
                {{ totalD }}单{{ totalOrderMun }}项
              </span>
            </el-col>
            <el-col :span="4" :offset="1">
              <span style=" display: inline-block;margin-right:15px">商品总额:</span>
              <span style="fontSize:16px;fontWeight:600;">￥{{ totalMoney.toFixed(2) }}</span>
            </el-col>
            <el-col :span="4">
              <span style=" display: inline-block;margin-right:15px">折扣:</span>
              <span style="fontSize:16px;color:#f74848;fontWeight:600;">
                -￥0.00
              </span>
            </el-col>
            <el-col :span="4">
              <span style=" display: inline-block;margin-right:15px">合计:</span>
              <span style="fontSize:18px;fontWeight:600;color:#f74848;margin-left:10px">
                ￥{{ (totalMoney - Number(0.0)).toFixed(2) }}
              </span>
            </el-col>
            <el-col :span="4" align="right">
              <button class="button-24" role="button" @click="sumbit">提交</button>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-container>
    <!-- 重选规格 -->
    <set-again-draw ref="setAgainDraw" v-if="showSAD" @closeSAD="closeSAD" />
  </div>
</template>
<script>
import SetAgainDraw from "./components/setAgainDraw.vue";
export default {
  name: "",
  components: { SetAgainDraw },
  computed: {},
  data() {
    return {
      navtype: 1,
      navArr: [
        {
          name: "购物车",
          id: 1,
          url: "/shoppingCar",
        },
        {
          name: "我的订单",
          id: 2,
          url: "/myOrder",
        },
      ],
      tableList: [],
      totalMoney: 0,
      totalOrderMun: 0,
      totalD: 0,
      link: null,
      showSAD: false,
    };
  },
  created() {
    this.query();
  },
  mounted() {},
  activated() {},
  methods: {
    choseNav(val) {
      this.navtype = val.id;
      this.$router.push(val.url);
    },
    query() {
      this.$axios
        .postJSON("/shop/public/common/buyer_cart/list", {
          is_choice: 0,
          page_size: 10000,
        })
        .then(res => {
          if (res.code == 200) {
            let data = res.data.data;
            if (res.data.data && res.data.data.length > 0) {
              data.forEach((el, index) => {
                let total = el.sku.map(i => {
                  let obj = i.com_sku?.price * Number(i.buy_count);
                  return obj;
                });
                el["totalPrice"] = this.getSum(total);
                el["allChecked"] = false;
                el.sku.forEach(itt => {
                  itt["is_selected"] = false;
                  itt["is_input"] = false;
                });
              });
              // let totalMoney = this.tableList.map(i => i.totalPrice);
              // this.totalMoney = this.getSum(totalMoney);

              // let totalOrderMun = this.tableList.map(i => i.sku.length);
              // this.totalOrderMun = this.getSum(totalOrderMun);
              // console.log(this.totalMoney);
            } 
            this.tableList = JSON.parse(JSON.stringify(data));
            console.log(this.tableList);
          }
        });
    },
    checkTotal() {
      let newArr = [];
      let data = JSON.parse(JSON.stringify(this.tableList));
      data.forEach((el, index) => {
        newArr[index] = el;
        newArr[index].sku = el.sku.filter(v => v.is_selected === true);
        let total = el.sku.map(i => {
          if (i.is_selected) {
            let obj = i.com_sku?.price * Number(i.buy_count);
            return obj;
          }
        });
        el["totalPrice"] = this.getSum(total) || 0;
      });
      let totalMoney = data.map(i => i.totalPrice);
      console.log(totalMoney);
      this.totalMoney = this.getSum(totalMoney);
      console.log(newArr);
      const result = newArr.filter(u => u.sku.length > 0).length;
      console.log(result);
      this.totalD = result;
      let totalOrderMun = newArr.map(i => i.sku.length);
      this.totalOrderMun = this.getSum(totalOrderMun);
      console.log(this.totalMoney);
    },
    getSum(arr) {
      return eval(arr.join("+"));
    },
    allChecked_btn(val, item) {
      console.log(val);
      if (val) {
        item.sku.forEach(i => {
          i.is_selected = true;
        });
      } else {
        item.sku.forEach(i => {
          i.is_selected = false;
        });
      }
      this.checkTotal();
    },
    checked_btn(val, item) {
      console.log(val);
      let flag = item.sku.every(i => i.is_selected === true);
      item.allChecked = JSON.parse(JSON.stringify(flag));
      this.checkTotal();
    },
    gotoHomePage(item) {
      this.link = location.origin + "/_shop/index?cid=" + item.seller_company_id;
      window.open(this.link);
    },
    setAgain(it) {
      console.log(it);
      this.showSAD = true;
      this.$nextTick(() => {
        this.$refs.setAgainDraw.open({ com_sku_id: it.com_sku.id, id: it.id }, val => {
          console.log(val); // 返回所选择的参数
          if (val) {
            let params = {
              id: it.id,
              seller_company_id: it.seller_company_id,
              com_sku_id: val.id,
              buy_count: it.buy_count,
              com_spu_other: [],
            };
            this.$axios.putJSON("/shop/public/common/buyer_cart/update", params).then(res => {
              if (res.code == 200) {
                console.log(res);
                this.$message.success("更改成功");
                this.query();
              }
            });
          }
        });
      });
    },
    closeSAD(val) {
      this.showSAD = val;
    },
    confirm(it) {
      console.log(it);
      if (!it.buy_count) {
        return this.$message.error("请输入订单数量");
      }
      let params = {
        id: it.id,
        seller_company_id: it.seller_company_id,
        com_sku_id: it.com_sku_id,
        buy_count: it.buy_count,
      };
      this.$axios.putJSON("/shop/public/common/buyer_cart/update", params).then(res => {
        if (res.code == 200) {
          console.log(res);
          this.$message.success("更改成功");
          this.query();
        }
      });
    },
    cancel(it) {
      it.is_input = false;
      it.buy_count = it.saveData;
    },
    editBtn(it) {
      it["saveData"] = it.buy_count;
      it.is_input = true;
    },
    deltBtn(it) {
      this.$confirm("此操作将删除该商品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios
            .deltJSON("/shop/public/common/buyer_cart/delete", {
              ids: [it.id],
            })
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.query();
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    sumbit() {
      if (this.totalOrderMun === 0 || this.totalD === 0) {
        return this.$message.error("请先勾选商品");
      }
      console.log(this.tableList);
      let sku_info = [];
      this.tableList.forEach(val => {
        val.sku.map(i => {
          if (i.is_selected) {
            let obj = {
              sku_id: i.com_sku_id,
              buy_count: Number(i.buy_count),
            };
            return sku_info.push(obj);
          }
        });
      });
      console.log(sku_info);
      let choice_list = {
        sku_info,
        type: 1, //1个人0企业
      };
      sessionStorage.setItem("choice_list", JSON.stringify(choice_list));
      setTimeout(() => {
        this.$router.push("/confirmOrder");
      }, 500);
      // 跟换下单页面的接口逻辑 这里传参废除
      // let params = {
      //   choice_list: choice_list,
      // };
      // this.$axios.postJSON("/shop/public/common/buyer_cart/choice", params).then(res => {
      //   if (res.code == 200) {
      //     this.$router.push("/confirmOrder");
      //   }
      // });
    },
  },
};
</script>
<style lang="scss" scoped>
#main_MyShopCar {
  // min-height: calc(90vh);
  padding-bottom: 1px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  height: 83vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.headline {
  background-color: #fff;
  position: sticky;
}
.nav_bar {
  padding: 15px 15px 10px 15px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nav {
    display: flex;
    align-items: center;
  }
  span {
    display: block;
    height: 38px;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    cursor: pointer;
    margin-left: 40px;
    font-weight: bolder;
  }
  .typeActive {
    color: $themeColor;
    border-bottom: 2px solid $themeColor;
  }
}
.head_info_bar {
  background-color: #fff;
  margin: 10px 0;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.head_info {
  display: flex;
  line-height: 45px;
  padding: 0 36px;
  margin-bottom: 15px;
  color: #101010;
  font-weight: 600;
  margin-bottom: 5px;
}
.main_box {
  background-color: #fff;
  padding: 10px 36px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  .main_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
    }
    i {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .main_info {
    display: flex;
    align-items: center;
    margin: 15px 0;
    min-height: 120px;
    box-sizing: border-box;
  }
  .main_bot {
    margin-top: 25px;
    line-height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    div {
      display: flex;
      align-items: center;
    }
  }

  .price_bar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 50px;
    p {
      margin: 0 50px;
    }
  }
}
.bot {
  background-color: #fff;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

  .part2 {
    margin-top: 10px;
  }
}

/* CSS */
.button-24 {
  width: 100%;
  background: #f74848;
  border: 1px solid #f74848;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.button-24:active {
  opacity: 0.5;
}

.links {
  margin-right: 5px;
  color: #66b1fc;
}
</style>
