var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.drawer)?_c('el-drawer',{attrs:{"visible":_vm.drawer,"wrapperClosable":false,"with-header":false,"direction":"rtl","size":"80%","id":"addSucai","destroy-on-close":true},on:{"update:visible":function($event){_vm.drawer=$event}}},[_c('el-container',{staticStyle:{"height":"100%"}},[_c('el-header',{attrs:{"height":"auto"}},[_c('fw-header',{attrs:{"titles":"重选规格"}})],1),_c('el-main',[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",attrs:{"stripe":"","highlight-hover-row":"","size":"small","border":false,"id":"toolbar_demo3","data":_vm.listData,"row-id":_vm.defaultKey,"radio-config":{ checkRowKey: _vm.defaultSelecteRow },"row-key":""}},[_c('vxe-column',{attrs:{"type":"radio","width":"60"}}),_c('vxe-column',{attrs:{"type":"seq","title":"序号","width":"60"}}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"80px","height":"80px"},attrs:{"src":row.cover_img_path,"preview-src-list":[row.cover_img_path],"fit":"contain"}})]}}],null,false,809467651)}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("商品名称")]),_c('div',[_vm._v("商品编号")]),_c('div',[_vm._v("商品小号")]),_c('div',[_vm._v("商品描述")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellipsisText",attrs:{"title":row.name || '--'}},[_vm._v(" "+_vm._s(row.name || "--")+" ")]),_c('div',{staticClass:"ellipsisText",attrs:{"title":row.serial_number || '--'}},[_vm._v(" "+_vm._s(row.serial_number || "--")+" ")]),_c('div',{staticClass:"ellipsisText",attrs:{"title":row.serial_number || '--'}},[_vm._v(" "+_vm._s(row.com_sku_goods && row.com_sku_goods.goods_small||"--")+" ")]),_c('div',{staticClass:"ellipsisText",attrs:{"title":row.info || '--'}},[_vm._v(" "+_vm._s(row.info || "--")+" ")])]}}],null,false,837926496)}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("商品规格")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [(row.spec_json)?_c('div',[_vm._l((row.spec_json),function(itt,indd){return _c('div',{key:indd,staticStyle:{"margin":"10px 0"}},[(indd < 2)?_c('p',{staticClass:"ellipsisText",attrs:{"title":itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name}},[_vm._v(" "+_vm._s(itt.spec_name)+" "+_vm._s(itt.spec_value_name)+" "+_vm._s(itt.spec_unit_name)+" ")]):_vm._e()])}),(row.spec_json.length > 2)?_c('el-popover',{attrs:{"placement":"right","width":"180","trigger":"hover"}},[_c('div',{staticStyle:{"padding":"10px"}},_vm._l((row.spec_json),function(itt,indd){return _c('p',{key:indd,attrs:{"title":itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name}},[_vm._v(" "+_vm._s(itt.spec_name)+" "+_vm._s(itt.spec_value_name)+" "+_vm._s(itt.spec_unit_name)+" ")])}),0),_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_vm._v("...")])]):_vm._e()],2):_c('div',[_vm._v(" -- ")])]}}],null,false,4144313023)}),_c('vxe-column',{attrs:{"min-width":"120"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("商品单位")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellipsisText",attrs:{"title":row.valuation_unit || '--'}},[_vm._v(" "+_vm._s(row.valuation_unit || "--")+" ")])]}}],null,false,3591350795)}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',[_vm._v("销售价")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"ellipsisText",attrs:{"title":row.price || '--'}},[_vm._v("￥"+_vm._s(row.price || "--"))])]}}],null,false,3680339619)})],1),_c('fw-pageNumber',{attrs:{"align":"right","pages":_vm.pages},on:{"changePage":_vm.changePage}})],1),_c('el-footer',{staticStyle:{"margin":"10px 0"}},[_c('el-row',[_c('el-col',{attrs:{"align":"center","span":24}},[_c('span',{staticClass:"chongzhiPlain"},[_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small"},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{staticStyle:{"width":"88px"},attrs:{"size":"small","type":"primary"},on:{"click":_vm.sumbit}},[_vm._v(" 提交 ")])],1)])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }