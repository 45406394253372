/* 地址列表数据弹窗 */
<template>
  <el-drawer
    v-if="drawer"
    :visible.sync="drawer"
    :wrapperClosable="false"
    :with-header="false"
    direction="rtl"
    size="80%"
    id="addSucai"
    :destroy-on-close="true"
  >
    <el-container style="height:100%">
      <el-header height="auto">
        <fw-header titles="重选规格" />
      </el-header>
      <el-main>
        <vxe-table
          class="mytable-scrollbar"
          stripe
          highlight-hover-row
          size="small"
          :border="false"
          ref="xTable1"
          id="toolbar_demo3"
          :data="listData"
          :row-id="defaultKey"
          :radio-config="{ checkRowKey: defaultSelecteRow }"
          row-key
        >
          <vxe-column type="radio" width="60"></vxe-column>
          <vxe-column type="seq" title="序号" width="60"></vxe-column>
          <vxe-column min-width="160">
            <template #default="{row}">
              <el-image
                style="width: 80px; height: 80px"
                :src="row.cover_img_path"
                :preview-src-list="[row.cover_img_path]"
                fit="contain"
              ></el-image>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <div>商品名称</div>
              <div>商品编号</div>
              <div>商品小号</div>
              <div>商品描述</div>
            </template>
            <template #default="{row}">
              <div class="ellipsisText" :title="row.name || '--'">
                {{ row.name || "--" }}
              </div>
              <div class="ellipsisText" :title="row.serial_number || '--'">
                {{ row.serial_number || "--" }}
              </div>
              <div class="ellipsisText" :title="row.serial_number || '--'">
                {{ row.com_sku_goods && row.com_sku_goods.goods_small||"--" }}
              </div>
              <div class="ellipsisText" :title="row.info || '--'">
                {{ row.info || "--" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <div>商品规格</div>
            </template>
            <template #default="{row}">
              <div v-if="row.spec_json">
                <div v-for="(itt, indd) in row.spec_json" :key="indd" style="margin: 10px 0">
                  <p
                    class="ellipsisText"
                    :title="itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name"
                    v-if="indd < 2"
                  >
                    {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{ itt.spec_unit_name }}
                  </p>
                </div>
                <el-popover
                  v-if="row.spec_json.length > 2"
                  placement="right"
                  width="180"
                  trigger="hover"
                >
                  <div style="padding: 10px">
                    <p
                      v-for="(itt, indd) in row.spec_json"
                      :key="indd"
                      :title="itt.spec_name + ' ' + itt.spec_value_name + ' ' + itt.spec_unit_name"
                    >
                      {{ itt.spec_name }}&nbsp;{{ itt.spec_value_name }}&nbsp;{{
                        itt.spec_unit_name
                      }}
                    </p>
                  </div>
                  <span slot="reference">...</span>
                </el-popover>
              </div>
              <div v-else>
                --
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="120">
            <template #header>
              <div>商品单位</div>
            </template>
            <template #default="{row}">
              <div class="ellipsisText" :title="row.valuation_unit || '--'">
                {{ row.valuation_unit || "--" }}
              </div>
            </template>
          </vxe-column>
          <vxe-column min-width="160">
            <template #header>
              <div>销售价</div>
            </template>
            <template #default="{row}">
              <div class="ellipsisText" :title="row.price || '--'">￥{{ row.price || "--" }}</div>
            </template>
          </vxe-column>
        </vxe-table>
        <fw-pageNumber align="right" :pages="pages" @changePage="changePage" />
      </el-main>
      <el-footer style="margin: 10px 0">
        <el-row>
          <el-col align="center" :span="24">
            <span class="chongzhiPlain">
              <el-button style="width: 88px" size="small" @click="close">取消</el-button>
              <el-button style="width: 88px" size="small" type="primary" @click="sumbit">
                提交
              </el-button>
            </span>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </el-drawer>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      drawer: false,
      callback: null,
      listData: [],
      rowStyle: {
        //表格行样式
        color: "#333",
        fontSize: "12px",
        height: "56px",
      },
      headerStyle: {
        //表格头部样式
        background: "#F5F5F5",
        color: "#333",
        fontSize: "14px",
        height: "40px",
      },
      defaultSelecteRow: 1,
      defaultKey: "id",
      id: null,
      pages: {
        //页码
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    open(val, callback) {
      console.log(val);
      if (val) {
        this.defaultSelecteRow = val.com_sku_id;
        this.id = val.id;
      }
      this.callback = callback;
      this.drawer = true;
      this.query();
    },
    close() {
      // this.drawer = false
      this.$emit("closeSAD", false);
    },

    query() {
      let params = {
        page: this.pages.pageNum,
        page_size: this.pages.pageSize,
        id: this.id,
      };
      this.$axios.postJSON("/shop/public/common/buyer_cart/sku_list", params).then(res => {
        if (res.code == 200) {
          this.listData = res.data.data;
          this.pages.total = res.data.total;
        }
      });
    },
    changePage(val) {
      //页码改变
      if (val.type == "size") {
        this.pages.pageSize = val.num;
      } else {
        this.pages.pageNum = val.num;
      }
      this.query();
    },

    sumbit() {
      const records = this.$refs.xTable1.selectRow;
      console.log(records);
      if (!records) {
        return this.$message.error("请先勾选数据!");
      } else {
        this.callback(records);
        this.$emit("closeSAD", false);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-drawer__body {
  padding-left: 25px;
  padding-right: 25px;
}
</style>
